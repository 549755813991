import * as React from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ProductCard } from 'components/ProductCard';
import { AsyncCatalog } from 'components/AsyncCatalog';

type Props = {
  readonly data: GatsbyTypes.SalePageQuery;
};

const SalePage = ({ data }: Props) => {
  const breadcrumbs = [
    {
      label: 'Каталог',
      url: '/catalog',
    },
  ];

  return (
    <Layout>
      <SEO title="Распродажа дня" />
      <div className="pb-8 border-b border-gray-90 mb-16">
        <div className="pc-row">
          <Breadcrumbs items={breadcrumbs} />
          <h1 className="text-black text-4xl font-bold leading-snug mb-10">Распродажа дня</h1>
          <div className="pc-free-items">
            {data.api.onSale.products.map((p) => (
              <div className="pc-free-item" key={p.rowId}>
                <ProductCard data={p} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <AsyncCatalog />
    </Layout>
  );
};

export const query = graphql`
  query SalePage {
    api {
      onSale {
        products {
          ...ProductCard_data
        }
      }
    }
  }
`;

export default SalePage;
